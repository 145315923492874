/* AnalyticsPage.css */
.mega-card {
    height: 100% !important;
    
  }
  
  .analytics-header {
    font-weight: 400 !important;
    font-size: 1.8rem !important;
  }

  .analytics-desc {
    font-size: 1.3rem !important;
  }