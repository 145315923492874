.container-custom {
  margin-top: 2rem;
}

/* Ensure container widths are set to 100% */
.container-custom {
  width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

header {
  width: 100%;
  overflow-x: hidden;
}

.modal-head1 {
  font-weight: 400 !important;
  font-size: xx-large !important;
}

.client-head {
  margin-top: 1rem;
  font-weight: 400;
  font-size: x-large;
}

.update-list {
  margin-top: 1rem;
}

/* Ensure the modal body is scrollable vertically and does not overflow horizontally */
.modal-body-custom {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: normal;
}

.update-alert {
  cursor: pointer;
  margin-bottom: 1rem;
}

.img-fluid-custom {
  display: block;
  margin: auto;
  width: 70%;
  height: auto;
}

.comment-list {
  list-style-type: none;
  padding-left: 0;
}

.comment-item {
  background: #f8f9fa;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.modal-custom .modal-dialog {
  max-width: 80%;
  margin-top: 3rem; /* Adjusted to make the modal wider */
}

@media (max-width: 767px) {
  .modal-custom .modal-dialog {
    max-width: 100%;
    margin-top: 1rem; 
  }
  .client-head {
    margin-bottom: 1rem;
  }

  .top-head {
    font-size: x-large !important;
  }

  .might-work {
    margin-top: 5rem !important;
  }

  .jumbo {
    max-height: 15vh;
  }

  .client-head1 {
    margin-bottom: -20rem !important;
  }
}

.rbc-time-gutter, .rbc-time-header-gutter {
  display: none;
}

@media (max-width: 769px) {
  .client-status {
    margin-top: 15rem !important;
  }
}

@media (max-width: 767px) {
  .project-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25vh;
  }

  .client-status {
    margin-top: 5rem !important;
  }
}

@media (max-width: 500px) {
  .modal-custom .modal-dialog {
    max-width: 100vw;
    margin: 0;
    height: 100vh;
  }

  .modal-custom .modal-content {
    height: 100vh;
  }

  .modal-body-custom {
    max-height: calc(100vh - 120px); /* Adjusted to make the modal body fill the screen */
    overflow-y: auto;
  }
}

@media (max-width: 410px) {
  .new-style {
  font-size: x-large;
  }
  }