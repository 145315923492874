/* src/components/TeamMembersPage.css */

.container.bootdey {
    margin-top: 20px;
  }
  
  .team img {
    width: 40%;
  }
  
  .team .content {
    width: 100%;
    margin: auto;
  }
  
  .team .content .title {
    font-size: 24px;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .team .content small {
    display: block;
    margin-top: 5px;
    font-size: large;
  }
  
  .team:hover {
    transform: scale(1.05);
  }
  
  .team {
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  }
  
  .team:hover {
    transform: translateY(-7px);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }
  
  .section-title .title {
    text-align: left;
  }
  