.footer-1 {
    background-color: black !important;
    color: white;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .thank-you {
      font-size: large !important;
  }
  
  .footer-bottom {
    background-color: black !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .hr {
    border-top: 1px solid white;
  }
  
  .btn-outline-light {
    border: 1px solid white;
    color: white;
  }
  
  .news-1 {
    font-size: 1.3rem !important;
  }
  
  .form-control {
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }
  
  .new-button {
    margin-top: 0.5rem;
  }
  
  @media (max-width: 768px) {
    .form-add {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }

    .icon-large {
      font-size: 1.5rem !important; /* Adjust the size as needed */
    }

    
  }

  @media (max-width: 500px) {
  .thank-you {
    font-size: medium !important;
}
}
  
  .icon-large {
    font-size: 2rem; /* Adjust the size as needed */
  }
  
 